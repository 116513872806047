<template lang="html">
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Chi tiết đơn hàng: <b class="pull-right">{{form.id}}</b></h6>
        </div>
        <div class="card-body order-title">
          <div class="right">
            <p>Ngày tạo: <b class="pull-right">{{ $util.dateTimeFormat(form.created_at) }}</b></p>
            <p>Ngày cập nhật: <b class="pull-right">{{ $util.dateTimeFormat(form.updated_at) }}</b></p>
            <p>Mã cuộc đua: <b class="pull-right">{{ form.race_id }}</b></p>
            <p>Race: <b class="pull-right">{{ form.race_title }}</b></p>
          </div>
          <div>
            <br>
            <p>Số lượng: <b class="pull-right">{{ form.quantity }}</b></p>
            <p>Giá: <b class="pull-right">{{ $util.formatMoney(form.race_price) }}</b></p>
            <p>Phí ship: <b class="pull-right">{{ $util.formatMoney(form.shipping_fee) }}</b></p>
            <p>Giảm giá: <b class="pull-right">{{ $util.formatMoney(form.discount) }}</b></p>
            <p>Donate: <b class="pull-right">{{ $util.formatMoney(form.donate) }}</b></p>
            <p>Tổng cộng: <b class="pull-right">{{ form.total_money }}</b></p>
            <p v-if="form.coupon_id && form.coupon && form.coupon.code">Coupon: <b class="pull-right badge badge-success">{{form.coupon.code}}</b></p>
          </div>
        </div>
      </div>
      <div class="card" v-if="form.payment_name">
        <div class="card-header">
          <h6 class="title">Cổng thanh toán</h6>
        </div>
        <div class="card-body order-title">
          <div>
            <p v-if="form.paygate_name" >Cổng thanh toán: <b class="pull-right">{{form.paygate_name}}</b></p>
            <p>Hình thức thanh toán: <b class="pull-right">{{ form.payment_name }}</b></p>
            <p v-if="form.status == 0">Trạng thái thanh toán: <b class="badge badge-danger pull-right">Chưa thanh toán</b></p>
            <div v-if="form.payment == 5 && form.status == 0 && isExpiredTimeBankTransfer">
              <div style="color: red;" class="pull-right">Đã hết hạn thanh toán</div>
            </div>
            <p v-if="form.status == 1">Trạng thái thanh toán: <b class="badge badge-success pull-right">Đã thanh toán</b></p>
            <div v-if="form.payment_transaction_id">
              <p>Mã giao dịch: <b>{{form.payment_transaction_id}}</b></p>
              <div v-if="form.payment_logs">
                <p v-for="(value, key) in form.payment_logs"><span class="payment-log-item">{{key}}</span>: <b>{{value}}</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Thông tin khách hàng
            <a class="pull-right" :href="`/#/users/${form.user_id}`" >{{form.user_id}}</a>
          </h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Tên đầy đủ:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Tên đầy đủ"
                class="full-width"
                v-model="form.name"
                :class="errors.has('name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="name"
                data-vv-as="Tên đầy đủ"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Tên:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Tên"
                class="full-width"
                v-model="form.first_name"
                :class="errors.has('first_name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="first_name"
                data-vv-as="Tên"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Họ:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Họ"
                class="full-width"
                v-model="form.last_name"
                :class="errors.has('last_name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="last_name"
                data-vv-as="Họ"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Email:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Email"
                class="full-width"
                v-model="form.email"
                :class="errors.has('email')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="email"
                data-vv-as="Email"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>Điện thoại:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Điện thoại"
                class="full-width"
                v-model="form.phone"
              >
              </el-input>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p class="required">Giới tính:</p>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.gender" class="full-width" placeholder="Giới tính">
                <el-option label="Nam" value="1"></el-option>
                <el-option label="Nữ" value="0"></el-option>
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p>Ngày sinh:</p>
            </div>
            <div class="col-sm-12">
              <el-date-picker
                class="full-width"
                v-model="form.birthday"
                type="date"
                placeholder="Ngày sinh">
              </el-date-picker>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p class="required">Tỉnh/TP:</p>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.province" placeholder="Tỉnh/TP" class="full-width">
                <el-option
                  v-for="option in provinces"
                  :key="option.id"
                  :label="option.name"
                  :value="option.name">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p class="required">Quận/huyện:</p>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.district" placeholder="Quận/huyện" class="full-width">
                <el-option
                  v-for="option in districts"
                  :key="option.id"
                  :label="option.name"
                  :value="option.name">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p class="required">Phường/xã:</p>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.ward" placeholder="Phường/xã" class="full-width">
                <el-option
                  v-for="option in wards"
                  :key="option.id"
                  :label="option.name"
                  :value="option.name">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p class="required">Địa chỉ:</p>
            </div>
            <div class="col-sm-12">
              <el-input
                type="text"
                placeholder="Địa chỉ"
                class="full-width"
                v-model="form.address"
                :class="errors.has('address')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="address"
                data-vv-as="Địa chỉ"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('address')">{{ errors.first('address') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Thông tin khác</h6>
        </div>
        <div class="card-body">

          <div class="form-group">
            <label>Thanh toán</label>
            <el-switch class="pull-right" v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
          </div>

          <div class="form-group">
            <label>Xóa đơn hàng</label>
            <el-switch class="pull-right" v-model="form.is_deleted" :active-value="1" :inactive-value="0"></el-switch>
          </div>

          <div class="form-group">
            <label>Đã hoàn tiền</label>
            <el-switch class="pull-right" v-model="form.is_refunded" :active-value="1" :inactive-value="0"></el-switch>
          </div>

          <div class="form-group">
            <label>Ghi chú</label>
            <el-input type="textarea" placeholder="Ghi chú" class="full-width" v-model="form.notes"></el-input>
          </div>

        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Thông tin đặt hàng</h6>
        </div>
        <div class="card-body form-card">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Mã </th>
                <th>Tên</th>
                <th>Cự ly</th>
                <th>Tổng tiền</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item of form.order_lines">
                <td>{{item.id}}</td>
                <td>{{item.name}}</td>
                <td>{{item.catalog_title}}</td>
                <td>{{$util.formatMoney(item.total)}}</td>
                <td>
                  <button type="button" class="btn btn-primary" @click="showModalDetail(item)">Xem chi tiết</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <el-dialog
      title="Thông tin đặt hàng"
      :visible.sync="dialogEditOrderVisible"
      width="90%"
    >
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h6 class="title">Chi tiết đơn hàng: <b class="pull-right">{{form_detail.id}}</b></h6>
            </div>
            <div class="card-body order-title">
              <div class="right">
                <p>Mã cuộc đua: <b class="pull-right">{{form_detail.race_id}}</b></p>
                <p>Race: <b class="pull-right">{{form_detail.race_title}}</b></p>
                <p>Cự ly: <b class="pull-right">{{form_detail.catalog_title}}</b></p>
                <p>Câu lạc bộ: <b class="pull-right" v-if="form_detail.club_id">{{form_detail.club}}</b>
                  <b class="pull-right" v-else>Không có</b>
                </p>
                <p>Nơi nhận huy chương: <b class="pull-right" v-if="form_detail.location_id">{{form_detail.location}}</b>
                  <b class="pull-right" v-else>Không có</b>
                </p>
              </div>
              <div>
                <br>
                <p v-for="item of form_detail.addons">{{ item.title }}: <b class="pull-right">{{ $util.formatMoney(item.price) }}</b></p>
                <p>Giá: <b class="pull-right">{{ $util.formatMoney(form_detail.race_price) }}</b></p>
                <p>Phí ship: <b class="pull-right">{{ $util.formatMoney(form_detail.shipping_fee) }}</b></p>
                <p>Giảm giá: <b class="pull-right">{{ $util.formatMoney(form_detail.discount) }}</b></p>
                <p v-if="form_detail.coupon_code">Mã giảm giá: <b class="pull-right badge badge-success">{{ form_detail.coupon_code }}</b></p>
                <p>Tổng cộng: <b class="pull-right">{{ $util.formatMoney(form_detail.total) }}</b></p>
                <p v-if="form_detail.coupon_id && form_detail.coupon && form_detail.coupon.code">Coupon: <b class="pull-right badge badge-danger">{{form_detail.coupon.code}}</b></p>
              </div>
            </div>
          </div>
          <div class="card" v-if="!isObjectEmpty(form_detail.offline_info)">
            <div class="card-header">
              <h6 class="title">Thông tin bổ sung</h6>
            </div>
            <div class="card-body order-title">
              <div>
                <p v-for="(value, key) in form_detail.offline_info"><span class="payment-log-item">{{ userInfoRaceOffline[key] }}</span>: <b>{{value}}</b></p>
              </div>
            </div>
          </div>
          <div class="card" v-if="form_detail.record">
            <div class="card-header">
              <h6 class="title">Gia hạn cuộc đua</h6>
            </div>
            <div class="card-body order-title">
              <p>Trạng thái: <b class="pull-right">{{ form_detail.record.renew_at ? "Đã gia hạn" : "Không gia hạn" }}</b></p>
              <p v-if="form_detail.record.renew_at">Ngày gia hạn: <b class="pull-right">{{ $util.dateTimeFormat(form_detail.record.renew_at) }}</b></p>
              <p>Ngày bắt đầu: <b class="pull-right">{{ $util.dateTimeFormat(form_detail.record.start_at) }}</b></p>
              <p>Ngày kết thúc: <b class="pull-right">{{ $util.dateTimeFormat(form_detail.record.end_at) }}</b></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h6 class="title">Thông tin khách hàng
                <a :href="`/#/users/${form_detail.user_id}`" >#{{form_detail.user_id}}</a>
              </h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Tên đầy đủ:</p>
                </div>
                <div class="col-sm-12">
                  <el-input
                    type="text"
                    placeholder="Tên đầy đủ"
                    class="full-width"
                    v-model="form_detail.name"
                    :class="errors.has('name')?'border-danger':''"
                    v-validate="'required'"
                    data-vv-name="name"
                    data-vv-as="Tên đầy đủ"
                  >
                  </el-input>
                  <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Tên:</p>
                </div>
                <div class="col-sm-12">
                  <el-input
                    type="text"
                    placeholder="Tên"
                    class="full-width"
                    v-model="form_detail.first_name"
                    :class="errors.has('first_name')?'border-danger':''"
                    v-validate="'required'"
                    data-vv-name="first_name"
                    data-vv-as="Tên"
                  >
                  </el-input>
                  <span class="text-danger" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Họ:</p>
                </div>
                <div class="col-sm-12">
                  <el-input
                    type="text"
                    placeholder="Họ"
                    class="full-width"
                    v-model="form_detail.last_name"
                    :class="errors.has('last_name')?'border-danger':''"
                    v-validate="'required'"
                    data-vv-name="last_name"
                    data-vv-as="Họ"
                  >
                  </el-input>
                  <span class="text-danger" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Email:</p>
                </div>
                <div class="col-sm-12">
                  <el-input
                    type="text"
                    placeholder="Email"
                    class="full-width"
                    v-model="form_detail.email"
                    :class="errors.has('email')?'border-danger':''"
                    v-validate="'required'"
                    data-vv-name="email"
                    data-vv-as="Email"
                  >
                  </el-input>
                  <span class="text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p>Điện thoại:</p>
                </div>
                <div class="col-sm-12">
                  <el-input
                    type="text"
                    placeholder="Điện thoại"
                    class="full-width"
                    v-model="form_detail.phone"
                  >
                  </el-input>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Giới tính:</p>
                </div>
                <div class="col-sm-12">
                  <el-select v-model="form_detail.gender" class="full-width" placeholder="Giới tính">
                    <el-option label="Nam" value="1"></el-option>
                    <el-option label="Nữ" value="0"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p>Ngày sinh:</p>
                </div>
                <div class="col-sm-12">
                  <el-date-picker
                    class="full-width"
                    v-model="form_detail.birthday"
                    type="date"
                    placeholder="Ngày sinh">
                  </el-date-picker>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Tỉnh/thành phố:</p>
                </div>
                <div class="col-sm-12">

                  <el-select v-model="form_detail.province" placeholder="Tỉnh/thành phố" class="full-width">
                    <el-option
                      v-for="option in provinces"
                      :key="option.id"
                      :label="option.name"
                      :value="option.name">
                    </el-option>
                  </el-select>

                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Quận/huyện:</p>
                </div>
                <div class="col-sm-12">
                  <el-select v-model="form_detail.district" placeholder="Quận/huyện" class="full-width">
                    <el-option
                      v-for="option in districts"
                      :key="option.id"
                      :label="option.name"
                      :value="option.name">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Phường/xã:</p>
                </div>
                <div class="col-sm-12">
                  <el-select v-model="form_detail.ward" placeholder="Phường/xã" class="full-width">
                    <el-option
                      v-for="option in wards"
                      :key="option.id"
                      :label="option.name"
                      :value="option.name">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p class="required">Địa chỉ:</p>
                </div>
                <div class="col-sm-12">
                  <el-input
                    type="text"
                    placeholder="Địa chỉ"
                    class="full-width"
                    v-model="form_detail.address"
                    :class="errors.has('address')?'border-danger':''"
                    v-validate="'required'"
                    data-vv-name="address"
                    data-vv-as="Địa chỉ"
                  >
                  </el-input>
                  <span class="text-danger" v-if="errors.has('address')">{{ errors.first('address') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h6 class="title">Thông tin khác</h6>
            </div>
            <div class="card-body">
              <div class="row" v-if="races && races.options && races.options.length">
                <div class="col-sm-12">
                  <p class="required">Đổi cuộc đua:</p>
                </div>
                <div class="col-sm-12">
                  <el-select v-model="form_detail.race_id" placeholder="Cuộc đua" style="width: 100%;">
                    <el-option
                      v-for="option in races.options"
                      :key="option.value"
                      :label="option.title"
                      :value="option.value">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="row" v-if="raceCatalogs && raceCatalogs.options && raceCatalogs.options.length">
                <div class="col-sm-12">
                  <p class="required">Đổi cự ly:</p>
                </div>
                <div class="col-sm-12">
                  <el-select v-model="form_detail.catalog_id" placeholder="Cự ly" style="width: 100%;">
                    <el-option
                      v-for="option in raceCatalogs.options"
                      :key="option.value"
                      :label="option.title"
                      :value="option.value">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div v-if="addAddons && raceAddons" class="row" v-for="(addon, index) in raceAddons" :key="index">
                <div class="col-sm-12">
                  <p>Tiện ích - {{ addon.title }}</p>
                </div>
                <div class="col-sm-12" v-if="addAddons[addon.id]">
                  <el-select v-model="addAddons[addon.id].variant_id" placeholder="Chọn phiên bản" style="width: 100%;">
                    <el-option value="">Không chọn</el-option>
                    <el-option
                      v-for="variant in addon.variants"
                      :key="variant.id"
                      :label="variant.title"
                      :value="variant.id">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-12" v-if="addAddons[addon.id] && addAddons[addon.id].variant_id && addon.engraved_name">
                  <el-input
                    type="text"
                    placeholder="Khắc tên"
                    class="full-width"
                    v-model="addAddons[addon.id].engraved_name">
                  </el-input>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p>Đổi CLB:</p>
                </div>
                <div class="col-sm-12">
                  <el-select v-model="form_detail.club_id" placeholder="Câu lạc bô" style="width: 100%;">
                    <el-option
                      v-for="option in listClubs.options"
                      :key="option.value"
                      :label="option.title"
                      :value="option.value">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p>Đổi nơi nhận huy chương:</p>
                </div>
                <div class="col-sm-12">
                  <el-select v-model="form_detail.location_id" placeholder="Nơi nhận huy chương" style="width: 100%;">
                    <el-option label="Không chọn" :value="null"></el-option>
                    <el-option
                      v-for="option in listLocations"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p>Khắc tên:</p>
                </div>
                <div class="col-sm-12">
                  <el-input
                    type="text"
                    placeholder="Khắc tên"
                    class="full-width"
                    v-model="form_detail.badge_name">
                  </el-input>
                </div>
              </div>

              <div class="row" v-if="form_detail.record && form_detail.record.bib">
                <div class="col-sm-12">
                  <p>Số BIB:</p>
                </div>
                <div class="col-sm-12">
                  <el-input
                    type="text"
                    placeholder="Số BIB"
                    class="full-width"
                    v-model="form_detail.record.bib">
                  </el-input>
                </div>
              </div>

              <div class="row" v-if="form_detail.record && form_detail.record.start_at">
                <div class="col-sm-12">
                  <p>Ngày bắt đầu tính kết quả:</p>
                </div>
                <div class="col-sm-12">
                  <el-date-picker
                      class="full-width"
                      v-model="form_detail.record.start_at"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                    >
                    </el-date-picker>
                </div>
              </div>

              <div class="row" v-if="form_detail.record && form_detail.record.end_at">
                <div class="col-sm-12">
                  <p>Ngày kết thúc tính kết quả:</p>
                </div>
                <div class="col-sm-12">
                  <el-date-picker
                    class="full-width"
                    v-model="form_detail.record.end_at"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                  >
                  </el-date-picker>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEditOrderVisible = false">Hủy</el-button>
        <el-button type="primary" @click="updateOrderLine">Cập nhật</el-button>
      </span>
    </el-dialog>
    <loading :active.sync="loading"></loading>
  </div>
</template>

<script>
import { Select, Option, Button, Tag, Input, Tabs, TabPane, Upload, DatePicker, Switch, Radio, Message, Dialog, MessageBox} from 'element-ui';
import { mapState } from 'vuex';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    ElSelect: Select,
    ElOption: Option,
    ElButton: Button,
    ElTag: Tag,
    ElInput: Input,
    ElTabs: Tabs,
    ElTabPane: TabPane,
    ElUpload: Upload,
    ElDatePicker: DatePicker,
    ElSwitch: Switch,
    ElRadio: Radio,
    ElDialog: Dialog,
    Loading
  },

  data() {
    return {
      loading: false,
      form: {},
      form_detail: {},
      dialogEditOrderVisible: false,
      catalogs: [],
      listAddons: [],
      addAddons: {},
      isDeleted: false,
      isRefunded: false,
      userInfoRaceOffline: {
        cmnd: 'CMND/CCCD',
        nhom_mau: 'Nhóm máu',
        tinh_trang_suc_khoe: 'Tình trạng sức khỏe',
        nguoi_lien_he: 'Người liên hệ',
        phone_number: 'Số điện thoại'
      }
    }
  },

  async beforeCreate() {
    let id = this.$route.params.id;
    await this.$store.dispatch('fetchProvinces');
    await this.$store.dispatch('fetchOrderDetail', id);
    await this.$store.dispatch('setPageTitle', 'Chỉnh sửa đơn hàng');
    await this.$store.dispatch('setCurrentActions', [{
      label: 'Gửi lại email',
      type: 'info',
      icon: '',
      callback: this.resendEmail
    },{
      label: 'Cập nhật đơn hàng',
      type: 'primary',
      icon: '',
      callback: this.save
    }])
  },

  computed: {
    ...mapState({
      raceAddons: state => state.raceAddons,
      originalForm: state => state.orderDetail
    }),

    races() {
      let data = this.$store.state.shortRaces || [];
      let arr = {};
      arr.options = [];
      for (let item of data) {
        arr.options.push({
          value: item.id,
          title: item.title
        });
      }
      return arr;
    },

    raceCatalogs() {
      let data = this.$store.state.raceCatalogs;
      this.catalogs = data;
      let arr = {};
      arr.options = [];
      for (let item of data) {
        arr.options.push({
          value: item.id,
          title: item.title
        });
      }
      return arr;
    },

    listClubs() {
      let data = this.$store.state.clubs;
      let arr = {};
      arr.options = [];
      for (let item of data) {
        arr.options.push({
          value: item.id,
          title: item.name
        });
      }
      return arr;
    },

    listLocations() {
      let data = this.$store.state.locations;
      return data;
    },

    provinces() {
      let data = this.$store.state.provinces;
      return data;
    },

    districts() {
      let data = this.$store.state.districts;
      return data;
    },

    wards() {
      let data = this.$store.state.wards;
      return data;
    },

    isExpiredTimeBankTransfer() {
      if (!this.form.created_at) {
        return false;
      }
      let now = new Date().getTime();
      let register = new Date(this.form.created_at).getTime();
      if (now - register > 48 * 60 * 60 * 1000) {
        return true;
      }
      return false;
    }
  },

  methods: {
    showModalDetail(order_line) {
      var temp = {};
      for(let item of this.raceAddons) {
        temp[item.id] = {
          variant_id: null,
          engraved_name: null
        };
        if (order_line.addons && order_line.addons.length) {
          order_line.addons.forEach(addon => {
            if (addon.addon_id == item.id && addon.variant_id) {
              temp[item.id].variant_id = addon.variant_id;
              temp[item.id].engraved_name = addon.engraved_name;
            }
          });
        }
      }
      this.$store.dispatch('fetchLocations', {
        race_id: order_line.race_id
      });
      this.addAddons = Object.assign({}, temp);
      this.form_detail = Object.assign({}, order_line);
      this.dialogEditOrderVisible = true;
    },

    async updateOrderLine() {
      this.loading = true;
      let data = Object.assign({}, this.form_detail);
      data.birthday = moment(data.birthday).format('YYYY-MM-DD HH:mm:ss');
      data.order_addons = Object.assign({}, this.addAddons);

      await this.$store.dispatch('updateOrderLine', data).then(res => {
        Message({
          message: 'Cập nhật thành công',
          type: 'success'
        });
        let id = this.$route.params.id;
        this.dialogEditOrderVisible = false;
        this.$store.dispatch('fetchOrderDetail', id);
      }).catch(err => {
        Message({
          dangerouslyUseHTMLString: true,
          message: err,
          type: 'error'
        });
      });

      this.loading = false;
    },

    getRaceCatalogs() {
      if (this.form_detail.race_id) {
        this.$store.dispatch('fetchRaceCatalogs', {
          race_id: this.form_detail.race_id
        });
      }
    },

    getRaceAddons() {
      if (this.form_detail.race_id) {
        this.$store.dispatch('fetchRaceAddons', {
          race_id: this.form_detail.race_id
        });
      }
    },

    fetchClubs() {
      this.$store.dispatch('fetchClubs');
    },

    fetchRaces() {
      this.$store.dispatch('fetchRacesShort');
    },

    resendEmail() {
      this.$store.dispatch('resendEmail', this.form.id).then(res => {
        if (res.success) {
          Message({
            message: 'Gửi email thành công',
            type: 'success'
          });
        } else{
          Message({
            message: res.message,
            type: 'error'
          });
        }
      });
    },

    cancel() {
      this.$router.push({name: 'AllOrders'});
    },

    isObjectEmpty(obj) {
      for(var prop in obj) {
        if(obj.hasOwnProperty(prop)) return false;
      }
      return true;
    },

    async save() {
      let self = this;
      await this.$validator.validateAll().then(async function (result) {
        if (!result) return;
        let data = Object.assign({}, self.form);
        data.birthday = moment(data.birthday).format('YYYY-MM-DD HH:mm:ss');
        data.addons = self.form.custom_addons;

        if ((data.is_deleted || data.is_refunded) && !data.notes)  {
          return Message({
            message: 'Nhập lý do xóa/hoàn tiền',
            type: 'error'
          });
        }

        self.loading = true;

        await self.$store.dispatch('updateOrder', data).then(res => {
          let id = self.$route.params.id;
          self.$store.dispatch('fetchOrderDetail', id);
          Message({
            message: 'Cập nhật thành công',
            type: 'success'
          });
        }, error => {
          Message({
            dangerouslyUseHTMLString: true,
            message: error,
            type: 'error'
          });
        });

        self.loading = false;

      });
    },

    async loadDistricts(province_id) {
      await this.$store.dispatch('fetchDistricts', province_id);
      if (this.form.district && this.districts && this.districts.length) {
        let item = this.districts.find(x => x.name === this.form.district);
        if (item && item.id) {
          this.$store.dispatch('fetchWards', item.id);
        }
      }
    },

    async loadDistrictsDetail(province_id) {
      await this.$store.dispatch('fetchDistricts', province_id);
      if (this.form_detail.district && this.districts && this.districts.length) {
        let item = this.districts.find(x => x.name === this.form_detail.district);
        if (item && item.id) {
          this.$store.dispatch('fetchWards', item.id);
        }
      }
    }

  },
  watch: {

    originalForm(val) {
      this.form = Object.assign({}, val);
      this.isDeleted = this.form.is_deleted;
      this.isRefunded = this.form.is_refunded;
    },

    'form.is_deleted': function(val) {
      if (this.isDeleted != val && val == 1) {
        Message({
          message: 'Đơn hàng sau khi bị xóa sẽ không khôi phục lại được',
          type: 'warning'
        });
      }
    },

    'form.is_refunded': function(val) {
      if (this.isRefunded != val && val == 1) {
        Message({
          message: 'Đơn hàng sau khi hoàn tiền sẽ không khôi phục lại được',
          type: 'warning'
        });
      }
    },

    raceCatalogs: function(newVal) {
      if (newVal && newVal.options) {
        if (newVal.options.findIndex(x => x.value == this.form.catalog_id) == -1) {
          this.form.catalog_id = undefined;
        }
      }
    },

    'form_detail.race_id': function(value) {
      this.addAddons = {};
      this.getRaceCatalogs();
      this.getRaceAddons();
      this.fetchClubs();
      this.fetchRaces();
    },

    raceAddons() {
      var temp = {};
      for(let item of this.raceAddons) {
        temp[item.id] = {
          variant_id: null,
          engraved_name: null
        };
        this.form_detail.addons.forEach(addon => {
          if (addon.addon_id == item.id && addon.variant_id) {
            temp[item.id].variant_id = addon.variant_id;
            temp[item.id].engraved_name = addon.engraved_name;
          }
        });
      }
      this.addAddons = Object.assign({}, temp);
    },

    'form_detail.club_id'(newValue, oldValue) {
      if (newValue) {
        let clubs = this.$store.state.clubs;
        for (let club of clubs) {
          if (club.id == newValue) {
            this.form.club = club.name;
          }
        }
      }
    },

    'form_detail.location_id'(newValue, oldValue) {
      if (newValue) {
        let locations = this.$store.state.locations;
        for (let location of locations) {
          if (location.id == newValue) {
            this.form.location = location.name;
          }
        }
      }
    },

    'form.province'(value) {
      if (this.provinces && this.provinces.length) {
        let item = this.provinces.find(x => x.name === value);
        if (item && item.id) {
          this.loadDistricts(item.id);
        }
      }
    },

    'form.district'(value) {
      if (this.districts && this.districts.length) {
        let item = this.districts.find(x => x.name === value);
        if (item && item.id) {
          this.$store.dispatch('fetchWards', item.id);
        }
      }
    },

    'form_detail.province'(value) {
      if (this.provinces && this.provinces.length) {
        let item = this.provinces.find(x => x.name === value);
        if (item && item.id) {
          this.loadDistrictsDetail(item.id);
        }
      }
    },

    'form_detail.district'(value) {
      if (this.districts && this.districts.length) {
        let item = this.districts.find(x => x.name === value);
        if (item && item.id) {
          this.$store.dispatch('fetchWards', item.id);
        }
      }
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.$store.state.orderDetail = [];
    this.$store.state.raceCatalogs = [];
    this.$store.state.races = [];
  }
}
</script>
<style lang="scss">
  .order-title .left{
    margin-right: 10px;
  }
  p {
    margin-bottom: 5px;
  }
  .el-input {
    margin-bottom: 10px;
  }
  .badge.badge-danger {
    white-space: normal;
    line-height: 13px;
    text-align: left;
  }
  .notes {
    margin-bottom: 20px;
  }
  .payment-log-item {
    text-transform: capitalize;
  }
  .navbar-nav {
    .el-button {
      margin-left: 10px !important;
    }
  }
  .el-dialog {
    margin-top: 5vh !important;
  }

  .el-dialog p {
    word-break: break-word;
  }

</style>
