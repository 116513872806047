var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", { staticClass: "title" }, [
              _vm._v("Chi tiết đơn hàng: "),
              _c("b", { staticClass: "pull-right" }, [
                _vm._v(_vm._s(_vm.form.id)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "card-body order-title" }, [
            _c("div", { staticClass: "right" }, [
              _c("p", [
                _vm._v("Ngày tạo: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.dateTimeFormat(_vm.form.created_at))),
                ]),
              ]),
              _c("p", [
                _vm._v("Ngày cập nhật: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.dateTimeFormat(_vm.form.updated_at))),
                ]),
              ]),
              _c("p", [
                _vm._v("Mã cuộc đua: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.form.race_id)),
                ]),
              ]),
              _c("p", [
                _vm._v("Race: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.form.race_title)),
                ]),
              ]),
            ]),
            _c("div", [
              _c("br"),
              _c("p", [
                _vm._v("Số lượng: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.form.quantity)),
                ]),
              ]),
              _c("p", [
                _vm._v("Giá: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.formatMoney(_vm.form.race_price))),
                ]),
              ]),
              _c("p", [
                _vm._v("Phí ship: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.formatMoney(_vm.form.shipping_fee))),
                ]),
              ]),
              _c("p", [
                _vm._v("Giảm giá: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.formatMoney(_vm.form.discount))),
                ]),
              ]),
              _c("p", [
                _vm._v("Donate: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.formatMoney(_vm.form.donate))),
                ]),
              ]),
              _c("p", [
                _vm._v("Tổng cộng: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.form.total_money)),
                ]),
              ]),
              _vm.form.coupon_id && _vm.form.coupon && _vm.form.coupon.code
                ? _c("p", [
                    _vm._v("Coupon: "),
                    _c("b", { staticClass: "pull-right badge badge-success" }, [
                      _vm._v(_vm._s(_vm.form.coupon.code)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm.form.payment_name
          ? _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _c("div", { staticClass: "card-body order-title" }, [
                _c("div", [
                  _vm.form.paygate_name
                    ? _c("p", [
                        _vm._v("Cổng thanh toán: "),
                        _c("b", { staticClass: "pull-right" }, [
                          _vm._v(_vm._s(_vm.form.paygate_name)),
                        ]),
                      ])
                    : _vm._e(),
                  _c("p", [
                    _vm._v("Hình thức thanh toán: "),
                    _c("b", { staticClass: "pull-right" }, [
                      _vm._v(_vm._s(_vm.form.payment_name)),
                    ]),
                  ]),
                  _vm.form.status == 0
                    ? _c("p", [
                        _vm._v("Trạng thái thanh toán: "),
                        _c(
                          "b",
                          { staticClass: "badge badge-danger pull-right" },
                          [_vm._v("Chưa thanh toán")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.payment == 5 &&
                  _vm.form.status == 0 &&
                  _vm.isExpiredTimeBankTransfer
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "pull-right",
                            staticStyle: { color: "red" },
                          },
                          [_vm._v("Đã hết hạn thanh toán")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.status == 1
                    ? _c("p", [
                        _vm._v("Trạng thái thanh toán: "),
                        _c(
                          "b",
                          { staticClass: "badge badge-success pull-right" },
                          [_vm._v("Đã thanh toán")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.payment_transaction_id
                    ? _c("div", [
                        _c("p", [
                          _vm._v("Mã giao dịch: "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.form.payment_transaction_id)),
                          ]),
                        ]),
                        _vm.form.payment_logs
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.form.payment_logs,
                                function (value, key) {
                                  return _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "payment-log-item" },
                                      [_vm._v(_vm._s(key))]
                                    ),
                                    _vm._v(": "),
                                    _c("b", [_vm._v(_vm._s(value))]),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", { staticClass: "title" }, [
              _vm._v("Thông tin khách hàng\n          "),
              _c(
                "a",
                {
                  staticClass: "pull-right",
                  attrs: { href: `/#/users/${_vm.form.user_id}` },
                },
                [_vm._v(_vm._s(_vm.form.user_id))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("name") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Tên đầy đủ",
                      "data-vv-name": "name",
                      "data-vv-as": "Tên đầy đủ",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _vm.errors.has("name")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("name"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("first_name") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Tên",
                      "data-vv-name": "first_name",
                      "data-vv-as": "Tên",
                    },
                    model: {
                      value: _vm.form.first_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "first_name", $$v)
                      },
                      expression: "form.first_name",
                    },
                  }),
                  _vm.errors.has("first_name")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("first_name"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("last_name") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Họ",
                      "data-vv-name": "last_name",
                      "data-vv-as": "Họ",
                    },
                    model: {
                      value: _vm.form.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "last_name", $$v)
                      },
                      expression: "form.last_name",
                    },
                  }),
                  _vm.errors.has("last_name")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("last_name"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(4),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("email") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Email",
                      "data-vv-name": "email",
                      "data-vv-as": "Email",
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                  _vm.errors.has("email")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("email"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(5),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    staticClass: "full-width",
                    attrs: { type: "text", placeholder: "Điện thoại" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(6),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      attrs: { placeholder: "Giới tính" },
                      model: {
                        value: _vm.form.gender,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "gender", $$v)
                        },
                        expression: "form.gender",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "Nam", value: "1" } }),
                      _c("el-option", { attrs: { label: "Nữ", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(7),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-date-picker", {
                    staticClass: "full-width",
                    attrs: { type: "date", placeholder: "Ngày sinh" },
                    model: {
                      value: _vm.form.birthday,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "birthday", $$v)
                      },
                      expression: "form.birthday",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(8),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      attrs: { placeholder: "Tỉnh/TP" },
                      model: {
                        value: _vm.form.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "province", $$v)
                        },
                        expression: "form.province",
                      },
                    },
                    _vm._l(_vm.provinces, function (option) {
                      return _c("el-option", {
                        key: option.id,
                        attrs: { label: option.name, value: option.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(9),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      attrs: { placeholder: "Quận/huyện" },
                      model: {
                        value: _vm.form.district,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "district", $$v)
                        },
                        expression: "form.district",
                      },
                    },
                    _vm._l(_vm.districts, function (option) {
                      return _c("el-option", {
                        key: option.id,
                        attrs: { label: option.name, value: option.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(10),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      attrs: { placeholder: "Phường/xã" },
                      model: {
                        value: _vm.form.ward,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ward", $$v)
                        },
                        expression: "form.ward",
                      },
                    },
                    _vm._l(_vm.wards, function (option) {
                      return _c("el-option", {
                        key: option.id,
                        attrs: { label: option.name, value: option.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(11),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("address") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Địa chỉ",
                      "data-vv-name": "address",
                      "data-vv-as": "Địa chỉ",
                    },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                  _vm.errors.has("address")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("address"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(12),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Thanh toán")]),
                _c("el-switch", {
                  staticClass: "pull-right",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Xóa đơn hàng")]),
                _c("el-switch", {
                  staticClass: "pull-right",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.is_deleted,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_deleted", $$v)
                    },
                    expression: "form.is_deleted",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Đã hoàn tiền")]),
                _c("el-switch", {
                  staticClass: "pull-right",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.is_refunded,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_refunded", $$v)
                    },
                    expression: "form.is_refunded",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Ghi chú")]),
                _c("el-input", {
                  staticClass: "full-width",
                  attrs: { type: "textarea", placeholder: "Ghi chú" },
                  model: {
                    value: _vm.form.notes,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "notes", $$v)
                    },
                    expression: "form.notes",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(13),
          _c("div", { staticClass: "card-body form-card" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _vm._m(14),
              _c(
                "tbody",
                _vm._l(_vm.form.order_lines, function (item) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(item.id))]),
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _c("td", [_vm._v(_vm._s(item.catalog_title))]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.$util.formatMoney(item.total))),
                    ]),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.showModalDetail(item)
                            },
                          },
                        },
                        [_vm._v("Xem chi tiết")]
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Thông tin đặt hàng",
            visible: _vm.dialogEditOrderVisible,
            width: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditOrderVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h6", { staticClass: "title" }, [
                    _vm._v("Chi tiết đơn hàng: "),
                    _c("b", { staticClass: "pull-right" }, [
                      _vm._v(_vm._s(_vm.form_detail.id)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "card-body order-title" }, [
                  _c("div", { staticClass: "right" }, [
                    _c("p", [
                      _vm._v("Mã cuộc đua: "),
                      _c("b", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.form_detail.race_id)),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v("Race: "),
                      _c("b", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.form_detail.race_title)),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v("Cự ly: "),
                      _c("b", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.form_detail.catalog_title)),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v("Câu lạc bộ: "),
                      _vm.form_detail.club_id
                        ? _c("b", { staticClass: "pull-right" }, [
                            _vm._v(_vm._s(_vm.form_detail.club)),
                          ])
                        : _c("b", { staticClass: "pull-right" }, [
                            _vm._v("Không có"),
                          ]),
                    ]),
                    _c("p", [
                      _vm._v("Nơi nhận huy chương: "),
                      _vm.form_detail.location_id
                        ? _c("b", { staticClass: "pull-right" }, [
                            _vm._v(_vm._s(_vm.form_detail.location)),
                          ])
                        : _c("b", { staticClass: "pull-right" }, [
                            _vm._v("Không có"),
                          ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c("br"),
                      _vm._l(_vm.form_detail.addons, function (item) {
                        return _c("p", [
                          _vm._v(_vm._s(item.title) + ": "),
                          _c("b", { staticClass: "pull-right" }, [
                            _vm._v(_vm._s(_vm.$util.formatMoney(item.price))),
                          ]),
                        ])
                      }),
                      _c("p", [
                        _vm._v("Giá: "),
                        _c("b", { staticClass: "pull-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$util.formatMoney(_vm.form_detail.race_price)
                            )
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v("Phí ship: "),
                        _c("b", { staticClass: "pull-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$util.formatMoney(
                                _vm.form_detail.shipping_fee
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v("Giảm giá: "),
                        _c("b", { staticClass: "pull-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$util.formatMoney(_vm.form_detail.discount)
                            )
                          ),
                        ]),
                      ]),
                      _vm.form_detail.coupon_code
                        ? _c("p", [
                            _vm._v("Mã giảm giá: "),
                            _c(
                              "b",
                              { staticClass: "pull-right badge badge-success" },
                              [_vm._v(_vm._s(_vm.form_detail.coupon_code))]
                            ),
                          ])
                        : _vm._e(),
                      _c("p", [
                        _vm._v("Tổng cộng: "),
                        _c("b", { staticClass: "pull-right" }, [
                          _vm._v(
                            _vm._s(_vm.$util.formatMoney(_vm.form_detail.total))
                          ),
                        ]),
                      ]),
                      _vm.form_detail.coupon_id &&
                      _vm.form_detail.coupon &&
                      _vm.form_detail.coupon.code
                        ? _c("p", [
                            _vm._v("Coupon: "),
                            _c(
                              "b",
                              { staticClass: "pull-right badge badge-danger" },
                              [_vm._v(_vm._s(_vm.form_detail.coupon.code))]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              !_vm.isObjectEmpty(_vm.form_detail.offline_info)
                ? _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h6", { staticClass: "title" }, [
                        _vm._v("Thông tin bổ sung"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-body order-title" }, [
                      _c(
                        "div",
                        _vm._l(
                          _vm.form_detail.offline_info,
                          function (value, key) {
                            return _c("p", [
                              _c("span", { staticClass: "payment-log-item" }, [
                                _vm._v(_vm._s(_vm.userInfoRaceOffline[key])),
                              ]),
                              _vm._v(": "),
                              _c("b", [_vm._v(_vm._s(value))]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.form_detail.record
                ? _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h6", { staticClass: "title" }, [
                        _vm._v("Gia hạn cuộc đua"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-body order-title" }, [
                      _c("p", [
                        _vm._v("Trạng thái: "),
                        _c("b", { staticClass: "pull-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.form_detail.record.renew_at
                                ? "Đã gia hạn"
                                : "Không gia hạn"
                            )
                          ),
                        ]),
                      ]),
                      _vm.form_detail.record.renew_at
                        ? _c("p", [
                            _vm._v("Ngày gia hạn: "),
                            _c("b", { staticClass: "pull-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.dateTimeFormat(
                                    _vm.form_detail.record.renew_at
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("p", [
                        _vm._v("Ngày bắt đầu: "),
                        _c("b", { staticClass: "pull-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$util.dateTimeFormat(
                                _vm.form_detail.record.start_at
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v("Ngày kết thúc: "),
                        _c("b", { staticClass: "pull-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$util.dateTimeFormat(
                                _vm.form_detail.record.end_at
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h6", { staticClass: "title" }, [
                    _vm._v("Thông tin khách hàng\n              "),
                    _c(
                      "a",
                      {
                        attrs: { href: `/#/users/${_vm.form_detail.user_id}` },
                      },
                      [_vm._v("#" + _vm._s(_vm.form_detail.user_id))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [
                        _vm._v("Tên đầy đủ:"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "full-width",
                          class: _vm.errors.has("name") ? "border-danger" : "",
                          attrs: {
                            type: "text",
                            placeholder: "Tên đầy đủ",
                            "data-vv-name": "name",
                            "data-vv-as": "Tên đầy đủ",
                          },
                          model: {
                            value: _vm.form_detail.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form_detail, "name", $$v)
                            },
                            expression: "form_detail.name",
                          },
                        }),
                        _vm.errors.has("name")
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.errors.first("name"))),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [_vm._v("Tên:")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "full-width",
                          class: _vm.errors.has("first_name")
                            ? "border-danger"
                            : "",
                          attrs: {
                            type: "text",
                            placeholder: "Tên",
                            "data-vv-name": "first_name",
                            "data-vv-as": "Tên",
                          },
                          model: {
                            value: _vm.form_detail.first_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form_detail, "first_name", $$v)
                            },
                            expression: "form_detail.first_name",
                          },
                        }),
                        _vm.errors.has("first_name")
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.errors.first("first_name"))),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [_vm._v("Họ:")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "full-width",
                          class: _vm.errors.has("last_name")
                            ? "border-danger"
                            : "",
                          attrs: {
                            type: "text",
                            placeholder: "Họ",
                            "data-vv-name": "last_name",
                            "data-vv-as": "Họ",
                          },
                          model: {
                            value: _vm.form_detail.last_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form_detail, "last_name", $$v)
                            },
                            expression: "form_detail.last_name",
                          },
                        }),
                        _vm.errors.has("last_name")
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.errors.first("last_name"))),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [_vm._v("Email:")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "full-width",
                          class: _vm.errors.has("email") ? "border-danger" : "",
                          attrs: {
                            type: "text",
                            placeholder: "Email",
                            "data-vv-name": "email",
                            "data-vv-as": "Email",
                          },
                          model: {
                            value: _vm.form_detail.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.form_detail, "email", $$v)
                            },
                            expression: "form_detail.email",
                          },
                        }),
                        _vm.errors.has("email")
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.errors.first("email"))),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", [_vm._v("Điện thoại:")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("el-input", {
                          staticClass: "full-width",
                          attrs: { type: "text", placeholder: "Điện thoại" },
                          model: {
                            value: _vm.form_detail.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form_detail, "phone", $$v)
                            },
                            expression: "form_detail.phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [
                        _vm._v("Giới tính:"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "full-width",
                            attrs: { placeholder: "Giới tính" },
                            model: {
                              value: _vm.form_detail.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.form_detail, "gender", $$v)
                              },
                              expression: "form_detail.gender",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "Nam", value: "1" },
                            }),
                            _c("el-option", {
                              attrs: { label: "Nữ", value: "0" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", [_vm._v("Ngày sinh:")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("el-date-picker", {
                          staticClass: "full-width",
                          attrs: { type: "date", placeholder: "Ngày sinh" },
                          model: {
                            value: _vm.form_detail.birthday,
                            callback: function ($$v) {
                              _vm.$set(_vm.form_detail, "birthday", $$v)
                            },
                            expression: "form_detail.birthday",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [
                        _vm._v("Tỉnh/thành phố:"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "full-width",
                            attrs: { placeholder: "Tỉnh/thành phố" },
                            model: {
                              value: _vm.form_detail.province,
                              callback: function ($$v) {
                                _vm.$set(_vm.form_detail, "province", $$v)
                              },
                              expression: "form_detail.province",
                            },
                          },
                          _vm._l(_vm.provinces, function (option) {
                            return _c("el-option", {
                              key: option.id,
                              attrs: { label: option.name, value: option.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [
                        _vm._v("Quận/huyện:"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "full-width",
                            attrs: { placeholder: "Quận/huyện" },
                            model: {
                              value: _vm.form_detail.district,
                              callback: function ($$v) {
                                _vm.$set(_vm.form_detail, "district", $$v)
                              },
                              expression: "form_detail.district",
                            },
                          },
                          _vm._l(_vm.districts, function (option) {
                            return _c("el-option", {
                              key: option.id,
                              attrs: { label: option.name, value: option.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [
                        _vm._v("Phường/xã:"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "full-width",
                            attrs: { placeholder: "Phường/xã" },
                            model: {
                              value: _vm.form_detail.ward,
                              callback: function ($$v) {
                                _vm.$set(_vm.form_detail, "ward", $$v)
                              },
                              expression: "form_detail.ward",
                            },
                          },
                          _vm._l(_vm.wards, function (option) {
                            return _c("el-option", {
                              key: option.id,
                              attrs: { label: option.name, value: option.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("p", { staticClass: "required" }, [
                        _vm._v("Địa chỉ:"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "full-width",
                          class: _vm.errors.has("address")
                            ? "border-danger"
                            : "",
                          attrs: {
                            type: "text",
                            placeholder: "Địa chỉ",
                            "data-vv-name": "address",
                            "data-vv-as": "Địa chỉ",
                          },
                          model: {
                            value: _vm.form_detail.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.form_detail, "address", $$v)
                            },
                            expression: "form_detail.address",
                          },
                        }),
                        _vm.errors.has("address")
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.errors.first("address"))),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h6", { staticClass: "title" }, [
                    _vm._v("Thông tin khác"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _vm.races && _vm.races.options && _vm.races.options.length
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("p", { staticClass: "required" }, [
                              _vm._v("Đổi cuộc đua:"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "Cuộc đua" },
                                  model: {
                                    value: _vm.form_detail.race_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form_detail, "race_id", $$v)
                                    },
                                    expression: "form_detail.race_id",
                                  },
                                },
                                _vm._l(_vm.races.options, function (option) {
                                  return _c("el-option", {
                                    key: option.value,
                                    attrs: {
                                      label: option.title,
                                      value: option.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.raceCatalogs &&
                    _vm.raceCatalogs.options &&
                    _vm.raceCatalogs.options.length
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("p", { staticClass: "required" }, [
                              _vm._v("Đổi cự ly:"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "Cự ly" },
                                  model: {
                                    value: _vm.form_detail.catalog_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form_detail,
                                        "catalog_id",
                                        $$v
                                      )
                                    },
                                    expression: "form_detail.catalog_id",
                                  },
                                },
                                _vm._l(
                                  _vm.raceCatalogs.options,
                                  function (option) {
                                    return _c("el-option", {
                                      key: option.value,
                                      attrs: {
                                        label: option.title,
                                        value: option.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.raceAddons, function (addon, index) {
                      return _vm.addAddons && _vm.raceAddons
                        ? _c("div", { key: index, staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c("p", [
                                _vm._v("Tiện ích - " + _vm._s(addon.title)),
                              ]),
                            ]),
                            _vm.addAddons[addon.id]
                              ? _c(
                                  "div",
                                  { staticClass: "col-sm-12" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "Chọn phiên bản",
                                        },
                                        model: {
                                          value:
                                            _vm.addAddons[addon.id].variant_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addAddons[addon.id],
                                              "variant_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "addAddons[addon.id].variant_id",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-option",
                                          { attrs: { value: "" } },
                                          [_vm._v("Không chọn")]
                                        ),
                                        _vm._l(
                                          addon.variants,
                                          function (variant) {
                                            return _c("el-option", {
                                              key: variant.id,
                                              attrs: {
                                                label: variant.title,
                                                value: variant.id,
                                              },
                                            })
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.addAddons[addon.id] &&
                            _vm.addAddons[addon.id].variant_id &&
                            addon.engraved_name
                              ? _c(
                                  "div",
                                  { staticClass: "col-sm-12" },
                                  [
                                    _c("el-input", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Khắc tên",
                                      },
                                      model: {
                                        value:
                                          _vm.addAddons[addon.id].engraved_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addAddons[addon.id],
                                            "engraved_name",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addAddons[addon.id].engraved_name",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e()
                    }),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("p", [_vm._v("Đổi CLB:")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-sm-12" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "Câu lạc bô" },
                              model: {
                                value: _vm.form_detail.club_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form_detail, "club_id", $$v)
                                },
                                expression: "form_detail.club_id",
                              },
                            },
                            _vm._l(_vm.listClubs.options, function (option) {
                              return _c("el-option", {
                                key: option.value,
                                attrs: {
                                  label: option.title,
                                  value: option.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("p", [_vm._v("Đổi nơi nhận huy chương:")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-sm-12" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "Nơi nhận huy chương" },
                              model: {
                                value: _vm.form_detail.location_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form_detail, "location_id", $$v)
                                },
                                expression: "form_detail.location_id",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Không chọn", value: null },
                              }),
                              _vm._l(_vm.listLocations, function (option) {
                                return _c("el-option", {
                                  key: option.id,
                                  attrs: {
                                    label: option.name,
                                    value: option.id,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("p", [_vm._v("Khắc tên:")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-sm-12" },
                        [
                          _c("el-input", {
                            staticClass: "full-width",
                            attrs: { type: "text", placeholder: "Khắc tên" },
                            model: {
                              value: _vm.form_detail.badge_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form_detail, "badge_name", $$v)
                              },
                              expression: "form_detail.badge_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.form_detail.record && _vm.form_detail.record.bib
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("p", [_vm._v("Số BIB:")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c("el-input", {
                                staticClass: "full-width",
                                attrs: { type: "text", placeholder: "Số BIB" },
                                model: {
                                  value: _vm.form_detail.record.bib,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form_detail.record, "bib", $$v)
                                  },
                                  expression: "form_detail.record.bib",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.form_detail.record && _vm.form_detail.record.start_at
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("p", [_vm._v("Ngày bắt đầu tính kết quả:")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c("el-date-picker", {
                                staticClass: "full-width",
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                },
                                model: {
                                  value: _vm.form_detail.record.start_at,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form_detail.record,
                                      "start_at",
                                      $$v
                                    )
                                  },
                                  expression: "form_detail.record.start_at",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.form_detail.record && _vm.form_detail.record.end_at
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("p", [_vm._v("Ngày kết thúc tính kết quả:")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c("el-date-picker", {
                                staticClass: "full-width",
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                },
                                model: {
                                  value: _vm.form_detail.record.end_at,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form_detail.record,
                                      "end_at",
                                      $$v
                                    )
                                  },
                                  expression: "form_detail.record.end_at",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogEditOrderVisible = false
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateOrderLine },
                },
                [_vm._v("Cập nhật")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function ($event) {
            _vm.loading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Cổng thanh toán")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Tên đầy đủ:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Tên:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Họ:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Email:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", [_vm._v("Điện thoại:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Giới tính:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", [_vm._v("Ngày sinh:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Tỉnh/TP:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Quận/huyện:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Phường/xã:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Địa chỉ:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Thông tin khác")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Thông tin đặt hàng")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Mã ")]),
        _c("th", [_vm._v("Tên")]),
        _c("th", [_vm._v("Cự ly")]),
        _c("th", [_vm._v("Tổng tiền")]),
        _c("th", [_vm._v("Hành động")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }